import React, {useContext, useState} from 'react';
import "./Signin.scss";
import {Link, useLocation, useNavigate} from "react-router-dom";
import createLink from "../../../libs/createLink";
import _ from "lodash";
import {useMutation} from "@tanstack/react-query";
import {loginUser} from "../../../api/public-voissa/users";
import useCurrentUser from "../../../queries/currentUser";
import qs from "qs";
import Drawer from "../Drawer/Drawer";
import Button, {BUTTON_VARIANTS} from "../UI/Button/Button";
import Container from '../UI/Container/Container';
import { Helmet } from 'react-helmet';
import { canonical } from '../../../libs/helper';
import { config } from '../../../config';
import Input from '../UI/Input/Input';
import TextError from '../UI/TextError/TextError';
import { ModalContext } from '../ModalManager';
import {ACTION_LOCKED} from "../../../hooks/useCurrentUserActionLocked";


const SIGNIN_SUBTITLE_LOCKED_MAP = {
    DEFAULT : ()=>"Connectez-vous à votre compte Voissa",
    SEARCH_MEMBERS : ()=>"Connectez-vous pour rechercher des membres",
    [ACTION_LOCKED.CREATE_LIKE] : (lockedContentOwner) => <>Connectez-vous pour aimer la publication de <b>{lockedContentOwner?.username}</b></>,
    [ACTION_LOCKED.CREATE_FOLLOW] : (lockedContentOwner) => <>Connectez-vous pour suivre le profil de <b>{lockedContentOwner?.username}</b></>,
    [ACTION_LOCKED.CREATE_FRIEND] : (lockedContentOwner) => <>Connectez-vous pour ajouter <b>{lockedContentOwner?.username}</b> à votre cercle</>,
    [ACTION_LOCKED.MESSENGER] : (lockedContentOwner) => <>Connectez-vous pour discuter avec <b>{lockedContentOwner?.username}</b></>,
    VIEW_PUBLICATION: (lockedContentOwner)=><>Connectez-vous pour voir la publication de <b>{lockedContentOwner?.username}</b></>
}

const initDataUser = {
    login : "",
    password : "",
    error : false,
    showPassword : false
}

const SigninForm = ({
        lockedReason,
        lockedContentOwner,
        lockedAction
    }) => {

    const [user, setUser] = useState(initDataUser);
    const navigate = useNavigate();
    const location = useLocation();
    const {openModal} = useContext(ModalContext);

    const {isLoading, error, isError, isSuccess : isSuccessLogin, mutate } = useMutation(data => loginUser(data),{
        onError : (error) =>{
            if (!!_.get(error, 'errors.passwordWeak')) navigate('/change-password', {state: {email:_.get(error, "errors.email")}});
        },
        onSuccess : () =>{
            if(location?.pathname === "/connexion"){
                window.location.href = "/";
            }
            else{
                window.location.reload();
            }
        }
    });

    useCurrentUser({enabled : isSuccessLogin, onSuccess : (currentUser) =>{
            if(currentUser?._id) {
                if (currentUser.role === "emailUnconfirmed" && currentUser.profile.isCompleted) {
                    let {aToken} = qs.parse(window.location.search, {ignoreQueryPrefix: true});
                    if(!aToken && window.location.pathname !== createLink("userMe", currentUser)){
                        sessionStorage.setItem(`${process.env.REACT_APP_TOKEN_NAME}_alert`, true);
                        navigate(createLink("userMe", currentUser));
                    }
                }
                else if (!currentUser.profile.isCompleted) {
                    let {aToken} = qs.parse(window.location.search, {ignoreQueryPrefix: true});
                    if(!aToken && window.location.pathname !== '/first-connexion'){
                        navigate('/first-connexion');
                    }
                }
                else{
                    window.location.reload();
                    // navigate(_redirectTo());
                    // return <Navigate to={_redirectTo()} />
                }
            }
        }});


    // const _redirectTo = () => {
    //     let currentDomain = window.location.hostname;
    //     let referrer = document.referrer;
    //     let redirection = '/';
    //     if (referrer.indexOf(currentDomain) >= 0 && referrer.indexOf(createLink("signin")) <= 0) {
    //         redirection = referrer.replace(window.location.origin, "");
    //     }
    //     return redirection;
    // }


    function _updateFieldUser(key, value){
        setUser({...user, [key] : value});
    }

    function _onSubmit(event){
        event.preventDefault();
        event.stopPropagation();
        let error = !(user.login.length && user.password.length);
        if(!error){
            mutate({
                login: user.login,
                password: user.password,
            });
        }
        else{
            setUser({...user, error});
        }
    }

    const connectionError = isError && error.statusCode === 401;
    const rateLimitError = isError && error.statusCode === 429;
    const banError = isError && error.statusCode === 400 && _.get(error, "errors.isBan");

    return(
        <div id="signin">
            <div className="signin-title">Connexion</div>
            <div className="signin-subtitle">
                {SIGNIN_SUBTITLE_LOCKED_MAP[lockedAction] ? SIGNIN_SUBTITLE_LOCKED_MAP[lockedAction](lockedContentOwner) : SIGNIN_SUBTITLE_LOCKED_MAP.DEFAULT()}
            </div>
            
            <form onSubmit={(e) => _onSubmit(e)} autoComplete="on" noValidate>
                <Input 
                    placeholder="Email ou pseudo"
                    name="login"
                    type="text"
                    full
                    className="signin-input"
                    autoComplete="username"
                    value={user.login}
                    onChange={e => _updateFieldUser("login", e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Mot de passe"
                    name="password" 
                    full
                    autoComplete="current-password" 
                    className="signin-input"
                    value={user.password}
                    onChange={e => _updateFieldUser("password", e.target.value)}
                />
                <Link className='signin-forget-pass' to={createLink("forgotPassword")}>Mot de passe oublié ?</Link>

                <TextError show={connectionError} center>Identifiant ou mot de passe invalide</TextError>
                <TextError show={rateLimitError} center>Trop de demandes, veuillez réessayer dans 10 minutes</TextError>
                <TextError show={banError} center>
                    Votre profil a été banni de Voissa {error && error.message !== "Bad Request" ? `pour la raison suivante "${error.message}"` : ""}<br/>
                    Pour toute question veuillez <Link to={createLink("contact")}>nous contacter</Link>
                </TextError>
                <Button variant={BUTTON_VARIANTS.REVERSE} type="submit" loading={isLoading} full big style={{marginBottom:'8px', marginTop:'16px'}}>
                    Connexion
                </Button>
                <Button variant={BUTTON_VARIANTS.OUTLINE} onClick={()=>openModal('Signup')} big full>
                    Créer un compte
                </Button>
            </form>
            
        </div>
    )
}

export const Signin = ({
    toggle = false,
    handleClose = () =>{},
    lockedReason,
    lockedContentOwner,
    lockedAction
 }) => {

    return (
        <Drawer
            open={toggle}
            handleClose={handleClose}
            small
        >
            <SigninForm
                lockedReason={lockedReason}
                lockedContentOwner={lockedContentOwner}
                lockedAction={lockedAction}
            />
        </Drawer>
    )
}

const SigninPage = () => {
    return (
        <Container small>
            <Helmet>
                <title>{config.preTitle}Connexion{config.postTitle}</title>
                <link rel="canonical" href={canonical(createLink("signin"), "", "signin")} />
            </Helmet>
            {/* <PageTitle heading={1}>Connexion</PageTitle> */}
            <SigninForm />
        </Container>
    )
}

export default SigninPage;
