import React, { createContext, useContext, useState } from 'react';
import Drawer from './Drawer/Drawer';


const DrawerContext = createContext();


export const DrawerProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSmall, setIsSmall] = useState(false);
    const [content, setContent] = useState(null); 
    const [headContent, setHeadContent] = useState(null); 
    const [closeBtn, setCloseBtn] = useState(true);
    
    const openDrawer = (newContent, {newHeadContent = null, showCloseBtn = true, small = false}) => {
        setContent(newContent); 
        setHeadContent(newHeadContent);
        setCloseBtn(showCloseBtn);
        setIsSmall(small);
        setIsOpen(true);
    };

    const closeDrawer = () => {
        setIsOpen(false);
        setContent(null);
        setContent(null);
        setIsSmall(false);
        setHeadContent(null);
    };

    return (
        <DrawerContext.Provider value={{ isOpen, openDrawer, closeDrawer }}>
            {children}
            <Drawer small={isSmall} open={isOpen} handleClose={closeDrawer} headContent={headContent} closeBtn={closeBtn}>
                {content} 
            </Drawer>
        </DrawerContext.Provider>
    );
};

export const useDrawer = () => {
    return useContext(DrawerContext);
};
