
const createLink = (type , data = {}) => {
    data = {
        username : "",
        slug : "",
        profile : {
            gender : 0
        },
        ...data
    };
    const myProfileBasePath = data?.slug?.length ? `/profils/${data.slug}` : "/mon-profil";
    //const myProfileBasePath = "/mon-profil";
    switch(type){
        //Profile Users
        case"userProfile":
            return `/profils/${data.slug}`;
        case"userMedias":
            return `/profils/${data.slug}/medias`;
        case"userFilActus":
            return `/profils/${data.slug}/fil-actualite`;
        
        case"userDating":
            return `/profils/${data.slug}/rencontre`;

        case"userRelationsFriends":
            return `/profils/${data.slug}/relations`;

        case"userRelationsMemberCircle":
            return `/profils/${data.slug}/relations/circle`;

        case"userRelationsFollowers":
            return `/profils/${data.slug}/relations/followers`;
        case"userRelationsFollowings":
            return `/profils/${data.slug}/relations/followings`;


        case"userStatusOne":
            return `/profils/${data.slug}/status/${data.status._id}`;

        case"userNotificationOne":
            return `/profils/${data.slug}/notification/${data.notification._id}`;
        
        case"userCollections":
            return `/profils/${data.slug}/collections`;
        
        case"userCollection":
            return `/profils/${data.slug}/collections/${data.album.slug}_${data.album._id}`;

        
        case"userArticles":
            return data && data.blog && data.blog.title ? `/blog-sexe-amateur/${data.slug}/${data.blog.slug}` : `/blog-sexe-amateur/${data.slug}`;

        case"userPostsThreads":
            return `/profils/${data.slug}/posts/threads`;
        case"userPostsResponses":
            return `/profils/${data.slug}/posts/reponses`;

            // Mon profile
        case"userMe":
            return `${myProfileBasePath}`;
        case"userMeMedias":
            return `${myProfileBasePath}/medias`;        
        case"userMeDating":
            return `${myProfileBasePath}/rencontre`;

        case"userMeRelationsFriends":
            return `${myProfileBasePath}/relations`;
        case"userMeRelationsMemberCircle":
            return `${myProfileBasePath}/relations/circle`;
        case"userMeRelationsFriendRequests":
            return `${myProfileBasePath}/relations/demandes-amis`;
        case"userMeRelationsFollowers":
            return `${myProfileBasePath}/relations/followers`;
        case"userMeRelationsFollowings":
            return `${myProfileBasePath}/relations/followings`;
        case"userMeRelationsFollowBack":
            return `${myProfileBasePath}/relations/follow-back`;

        case"userMeStatusOne":
            return `${myProfileBasePath}/status/${data.status._id}`;

        case"userMeNotificationOne":
            return `${myProfileBasePath}/notification/${data.notification._id}`;
        
        case"userMeCollections":
            return `${myProfileBasePath}/collections`;
        
        case"userMeCollection":
            return `${myProfileBasePath}/collections/${data.album.slug}_${data.album._id}`;
        
        case"userMeArticles":
            //return `${myProfileBasePath}/articles`;
            return data && data.blog && data.blog.title ? `/blog-sexe-amateur/${data.slug}/${data.blog.slug}` : `/blog-sexe-amateur/${data.slug}`;

        case"userMeFavories":
            return `/mes-favoris`;

            //PROFILE ME SETTINGS
        case"userMeSettings":
            return `/mes-parametres`;
        
        case"userMeSettingsOptionsNotifications":
            return `/mes-parametres/options-notifications`;

        case"userMeSettingsDating":
            return `/mes-parametres/rencontre`;

        case"userMeSettingsProfile":
            return `/mes-parametres/profil`;

        case"userMeSettingsFriendsAndRequest":
            return `/mes-parametres/amis-et-demandes`;

        case"userMeSettingsEmailAndPassword":
            return `/mes-parametres/email-mdp`;

        case"userMeSettingsSignature":
            return `/mes-parametres/signature`;

        case"userMeSettingsBlocage":
            return `/mes-parametres/blocage`;

        case"userMeSettingsAbonnement":
            return `/mes-parametres/abonnement`;
        case"userMeSettingsCertification":
            return `/mes-parametres/certification`;

        // Page Photos
        case"photos":
            return `/photos-sexe`;
        
        // Page Videos
        case"videos":
            return `/videos-sexe`;
        
        // Page MediaViewer
        case"mediaviewerPhoto":
            return `/xxx-photos/${data.media.slug}_${data.media._id}`;
        
        case"mediaviewerVideo":
            return `/xxx-videos/${data.media.slug}_${data.media._id}`;
        
        // Actualité
        case"actuality":
            return `/fil-actualite`;
        
        // Moderation Commentaire
        case"commentsModeration":
            return `/moderation-commentaire`;

        case"commentsModerationArticles":
            return `/moderation-commentaire/articles`;

        case"commentsModerationStories":
            return `/moderation-commentaire/histoires`;

        case"commentsModerationStatus":
            return `/moderation-commentaire/status`;
        
        // Forum
        case"forum":
            return `/forum-sex`;
        case"forumCategories":
            return `/forum-sex/categories`;
        case"forumLastTopics":
            return `/forum-sex/derniers-messages`;
        
        case"forumCategory":
            return data.categoryParent && data.categoryParent.slug ? `/forum-sex/${data.categoryParent.slug}/${data.category.slug}` : `/forum-sex/${data.category.slug}`;
    
        case"forumTopic":
            let topicUrl =  data.categoryParent && data.categoryParent.slug ? `/forum-sex/${data.categoryParent.slug}/${data.category.slug}/topic/${data.topic.slug}_${data.topic._id}` : `/forum-sex/${data.category.slug}/topic/${data.topic.slug}_${data.topic._id}`;
            topicUrl = data.topic.page >1 ? `${topicUrl}/${data.topic.page}` : topicUrl;
            return data.topic.ancre ? `${topicUrl}/${data.topic.ancre}` : topicUrl;

         // Threads
        case"threads":
            return `/threads`;
        case"threadsCategories":
            return `/threads/categories`;
        
        case"threadsCategory":
            return data.categoryParent && data.categoryParent.slug ? `/threads/${data.categoryParent.slug}/${data.category.slug}` : `/threads/${data.category.slug}`;
    
        case"threadsTopic":
            let threadsUrl =  data.categoryParent && data.categoryParent.slug ? `/threads/${data.categoryParent.slug}/${data.category.slug}/topic/${data.topic.slug}_${data.topic._id}` : `/forum-sex/${data.category.slug}/topic/${data.topic.slug}_${data.topic._id}`;
            threadsUrl = data.topic.page >1 ? `${threadsUrl}/${data.topic.page}` : threadsUrl;
            return data.topic.ancre ? `${threadsUrl}/${data.topic.ancre}` : threadsUrl;
    
        // Articles
        case"articles":
            return `/blog-sexe-amateur`;
        
        case"article":
            return `/blog-sexe-amateur/${data.slug}/${data.article.slug}_${data.article._id}`;
        
        // Stories
        case"stories":
            return `/histoires-de-sexe`;

        case"storiesRules":
            return `/histoires-de-sexe/regles`;
        
        // case"story":
        //     return `/histoires-de-sexe/${data.story.slug}_${data.story._id}`;
        
        default : return "";
        // Dating
        case"dating":
            return `/rencontre`;

        case"trustSecurity":
            return `/confiance-et-securite`;

        case"parentalControl":
            return `/controle-parental`;

        case"signup":
            return `/inscription`;

        case"signin":
            return `/connexion`;

        case"firstConnexion" :
            return `/first-connexion`;
        ;

        case"chatWebcam":
            return `/chat-webcams`;

        case"messenger":
            return `/messenger`;
        case"messengerRoom":
            return `/messenger/${data.roomId}`;
        case"messengerCreate":
            return `/messenger/new`;
        case"forgotPassword":
            return `/forgot-password`;

        case"contact":
            return `/contact`;
        case"webcams":
            return `/webcams`;
        case"history":
            return `/historique`;

        case"charter":
            return `/charte`;
    
        case"cgus":
            return `/cgus`;
    
        /*case"deleteAccount":
            return `/suppression-de-compte`;*/
    
        case"privacyPolicy":
            return `/privacypolicy`;
    
        case"recherche":
            return `/recherche`;

        case"pcModerator" :
            return `/pc-moderateur`

        case"deleteAccount" :
            return `/supprimer-mon-compte`;

        case"facette":
            return data.slug ? `/f/${data.slug}` : `/f/`;

        case"comparatorDating":
            return "/comparateur/rencontres";

        case"tagModerationQualifications":
            return "/tag-moderations/qualifications";
        case"tagModerationVerifications":
            return "/tag-moderations/verifications";
        case"tagModerationTreatmentVideo":
            return "/tag-moderations/traitements-video";

        case"tagModerationMediasSfw":
            return "/tag-moderations/medias-sfw";

        case"tagPhoto":
            return data.slug ? `/photo-sexe/${data.tag.slug}/${data.slug}${data.showAll === "all" ? "/all" : ""}` : `/photo-sexe/${data.tag.slug}`;
        case"tagVideo":
            return data.slug ? `/video-sexe/${data.tag.slug}/${data.slug}${data.showAll === "all" ? "/all" : ""}` : `/video-sexe/${data.tag.slug}`;
        case"tagStory":
            return data.slug ? `/histoire-de-sexe/${data.tag.slug}/${data.slug}${data.showAll === "all" ? "/all" : ""}` : `/histoire-de-sexe/${data.tag.slug}`;
        case"tagArticle":
            return data.slug ? `/article-sexe/${data.tag.slug}/${data.slug}${data.showAll === "all" ? "/all" : ""}` : `/article-sexe/${data.tag.slug}`;

        case"vMessenger":
            return "/v-messenger";

        case"subscription":
            return "/paiement";
    }
};

// createLink("charter");
export default createLink;
